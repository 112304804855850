.required::after {
    content: "*";
    color: red;
    margin-right: 2px;
    margin-left: 2px;
  }
  
  .card-header{
    background-color: rgba(113, 113, 114, 0);
    border-bottom: 0.5px solid rgba(174, 174, 174, 0.141);
    /* border-bottom-color: none; */
    /* border-bottom-color: rgb(0, 5, 0); */
  }

  .card {
    border-color: rgba(229, 231, 229, 0.548);
    border-radius: 18px;
  }

  .form-control{
    border-color: rgba(206, 208, 206, 0.76);
    border-radius: 12px;
  }

  .select{
    border-color: rgba(206, 208, 206, 0.76);
    border-radius: 12px;
  }