.custom-modal .modal-dialog {
  max-width: 100%;
  width: 100%;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /*background: #fafafa;*/
  background-color: #f1f9ff;
  font-size: 14px;

}

.custom-modal .modal-content {
  height: 600px;
  font-family: "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /*background: #fafafa;*/
  background-color: #f1f9ff;
  font-size: 14px;
}