.required::after {
    content: "*";
    color: red;
    margin-right: 2px;
    margin-left: 2px;
  }

  .card-header{
    background-color: rgba(113, 113, 114, 0);
    /* border-bottom: 0.5px solid rgba(0,0,0,.125); */
    border-bottom: none;
    
  }

  .card {
    border-color: rgba(229, 231, 229, 0.548);
    border-radius: 18px;
  }