/* img {
  display: block;
  max-width: 100%;
  height: auto;
} */

/*=============== LOGIN ===============*/
.login {
  position: relative;
  height: 90vh;
  display: grid;
  align-items: center;
  margin-left: 165px;
}

.login__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Make sure the background image is behind other content */
}

.login__form {
  position: relative;
  margin-inline: 1.5rem;
  background-color: hsla(233, 53%, 39%, 0.187);
  border: 2px solid hsla(0, 44%, 92%, 0.449);
  margin-top: 250px;
  padding-top: 80px;
  padding-bottom: 60px;
  color: var(--white-color);
  border-radius: 2rem;
  backdrop-filter: blur(16px);
}

.loginform_img {
  align-items: center;
  justify-content: center;
}

.login__title {
  text-align: center;
  /* font-size: var(--h1-font-size); */
  /* font-size: large; */
  margin-bottom: 1.25rem;
}

.login__inputs,
.login__box {
  display: grid;
}

.login__inputs {
  row-gap: 1.25rem;
  margin-bottom: 1rem;
}

.login__box {
  grid-template-columns: 1fr max-content;
  column-gap: 0.75rem;
  align-items: center;
  border: 2px solid hsla(0, 0%, 100%, 0.579);
  padding-inline: 1.25rem;
  border-radius: 4rem;
}

.login__input,
.login__button {
  border: none;
  outline: none;
}

/* .login__button {
  background: black;
} */

.login__input {
  width: 100%;
  background: none;
  color: var(--white-color);
  padding-block: 1rem;
}

.login__input::placeholder {
  color: var(--white-color);
}

.login__box i {
  font-size: 1.25rem;
}

.login__check,
.login__check-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login__check {
  margin-bottom: 1rem;
  font-size: var(--small-font-size);
}

.login__check-box {
  column-gap: 0.5rem;
}

.login__check-input {
  width: 1rem;
  height: 1rem;
  accent-color: var(--white-color);
}

.login__button {
  width: 100%;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(60, 96, 174, 0.367);
  border-radius: 4rem;
  font-size: large;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.6s; /* Add a smooth transition effect */

  /* Add this to set the default button color */
  color: var(--black-color);
}

.login__button:hover {
  background-color: #3c60ae; /* Change the background color on hover */
  color: white; /* Change the text color on hover */
}

/*=============== Responsive ===============*/
/* For extra-large devices */
@media screen and (min-width: 1200px) {
  .login__form {
    width: 420px;
  }
  .login__title {
    font-size: 2.5rem; /* Adjust font size for extra-large screens */
  }
}

/* For large devices */
@media screen and (min-width: 992px) {
  .login {
    justify-content: center;
  }
  .login__form {
    width: 420px;
  }
  .login__title {
    margin-bottom: 2rem;
    font-size: 2rem; /* Adjust font size for larger screens */
  }
}

/* For medium screens, adjust margins and font size */
@media screen and (max-width: 768px) {
  .login {
    justify-content: center;
  }
  .login__form {
    width: 90%;
    margin: 100px auto;
    padding-inline: 1.5rem;
  }
  .login__title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

/* For medium devices */
@media screen and (min-width: 575px) {
  .login {
    justify-content: center;
  }
  .login__form {
    width: 420px;
    padding-inline: 2.5rem;
  }
  .login__title {
    margin-bottom: 2rem;
  }
}

/* For very small screens, further adjust margins and font size */
@media screen and (max-width: 480px) {
  .login {
    margin-left: 20px;
    margin-right: 20px;
  }
  .login__form {
    width: 100%;
    /* margin: 50px auto; */
    padding-inline: 1rem;
  }
  .login__title {
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
  }
}

/* For small devices */
/* @media screen and (max-width: 575px) {
  .login {
    justify-content: center;
  }
  .login__form {
    width: 420px;
    padding-inline: 2.5rem;
  }
  .login__title {
    margin-bottom: 2rem;
  }
} */
