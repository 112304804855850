.selected {
  background-color: gold;
  color: #fff;
  font-weight: bold;
}

.header-left {
  flex-grow: 1;
}

.header-right {
  flex-shrink: 0;
}
